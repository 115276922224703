/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React, {useEffect} from 'react';
import {Animate, AuthContext, Button, Message, Row} from 'components/lib';
import {useAuth0} from "@auth0/auth0-react";
import {useContext} from 'react';
import {useSearchParams} from 'react-router-dom';
export function Signin(props) {
  const {loginWithPopup, user, isAuthenticated, getAccessTokenSilently, logout} = useAuth0();
  const authContext = useContext(AuthContext)
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const authenticate = async () => {
      try {
        let accessToken;
        accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: process.env.REACT_APP_AUTH0_TOKEN_SCOPE,
          },
        });
        if (accessToken) {
          authContext.signin(accessToken)
        }
      } catch (e) {
        console.log(e.message);
        if (e?.message === 'Unknown or invalid refresh token.') {
          localStorage.clear();
          logout({logoutParams: {returnTo: `${window.location.origin}/signin`}})
        }
        throw e;
      }
    }

    if (isAuthenticated) {
      authenticate()
    }

  }, [isAuthenticated, user])

  return (
    <Animate type='pop'>
      <Row title='Provider Sign In' color='black'>
        {searchParams.get("reason") === 'account_disabled' ?
          <Message
            type={'error'}
            title={"Account Disabled"}
            text={"Your account has been disabled"}
            button={false}
          /> : <></>
        }

        {!searchParams.get("reason") ? <Button
          text='Sign in'
          color='green'
          className='mx-auto mb-5  w-80'
          rounded iconButton small
          action={() => loginWithPopup()} /> : <></>}
      </Row>
    </Animate>
  );
}
