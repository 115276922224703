/***
*
*   AUTHENTICATION
*   Auth provider to manage auth functions throughout
*   the application. <PrivateRoute> component to
*   protect internal application routes from unauthenticated
*   access.
*
**********/

import {useState, useEffect, createContext} from 'react';
import axios from 'axios';
import {Navigate} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import {useContext} from 'react';

// auth context
export const AuthContext = createContext();

const useAPI = require('components/lib').useAPI;
const Event = require('components/lib').Event;
const permissions = require('./permissions');

const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes in milliseconds

export function AuthProvider(props) {
  const cache = JSON.parse(localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined' ? localStorage.getItem('user') : null);
  const [user, setUser] = useState(cache);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const {logout, isAuthenticated} = useAuth0();

  // Update last activity time on user interaction
  const updateLastActivity = () => {
    setLastActivity(Date.now());
  };

  // Set up activity tracking
  useEffect(() => {
    if (isAuthenticated) {
      // Add event listeners for user activity
      const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart'];
      events.forEach(event => {
        window.addEventListener(event, updateLastActivity);
      });

      // Check for inactivity every minute
      const intervalId = setInterval(() => {
        const currentTime = Date.now();
        if (currentTime - lastActivity >= INACTIVITY_TIMEOUT) {
          signout().then(() => {});
        }
      }, 30000); // Check every minute

      // Cleanup
      return () => {
        events.forEach(event => {
          window.removeEventListener(event, updateLastActivity);
        });
        clearInterval(intervalId);
      };
    }
  }, [isAuthenticated, lastActivity, signout]);

  async function signin(accessToken) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    const userReqData = await axios({method: 'get', url: '/api/auth/me', headers: {Authorization: 'Bearer ' + accessToken}});

    const userPayload = userReqData.data
    localStorage.setItem('user', JSON.stringify(userPayload));
    Event('signin');

    if (!userPayload.verified)
      return window.location = '/signup/verify';

    return window.location = '/dashboard';
  }

  function updateEmailVerificationToken(token) {
    localStorage.setItem('emailVerificationToken', token);
  }

  async function signout(event, disabled = false, logoutUrl = null) {
    localStorage.clear();

    if (disabled) {
       logout({logoutParams: {returnTo: `${window.location.origin}/signin?reason=account_disabled`}})
    } else if (!logoutUrl) {
      logout({logoutParams: {returnTo: `${window.location.origin}`}})
    }

    if (logoutUrl) {
      logout({logoutParams: {returnTo: logoutUrl}})
    }
  }

  function update(data) {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      for (let key in data) {
        if (Array.isArray(data[key])) {
          user[key] = data[key]
        }
        else if (typeof data[key] === 'object') {
          for (let innerKey in data[key]) {
            user[key][innerKey] = data[key][innerKey]
          }
        }
        else {
          user[key] = data[key];
        }
      }

      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
    }
  }

  return (
    <AuthContext.Provider value={{
      user: user,
      signin: signin,
      updateEmailVerificationToken: updateEmailVerificationToken,
      signout: signout,
      update: update,
      switchAccount: () => {},
      permission: permissions[user?.permission]
    }}
      {...props} />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  const token = props.token;
  const user = props.user;
  const path = window.location.pathname;
  const authContext = useContext(AuthContext);

  if (token) {
    const userHasPermission = Object.prototype.toString.call(props.permission) === '[object Array]'
      ? props.permission.some(p => permissions[user.permission][p]) : permissions[user.permission][props.permission];

    if (userHasPermission) {
      if (user.verified) {
        if (path === '/signup/verify')
          return <Navigate to='/dashboard' />
      }
      else {
        if (path !== '/account/profile' && path !== '/signup/verify')
          return <Navigate to='/signup/verify' />;
      }

      return props.children;
    }
  }

  authContext.signout(null, false, `${window.location.origin}/signin`);
}
